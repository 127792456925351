import { gsap, ScrollTrigger, SplitText } from "../layout"
// import { auto } from "@popperjs/core";

// custom library
import { animateCursor } from '../modules/cursor';
import "@lottiefiles/lottie-player"
import LottieInteractivity from "@lottiefiles/lottie-interactivity"
import Swiper from 'swiper';
import { Autoplay } from 'swiper/modules';

gsap.registerPlugin(ScrollTrigger, SplitText);

function bannerTextAnimation() {
    let parentClass

    if (window.innerWidth >= 768) {
        parentClass = '.banner__holder'
    } else {
        parentClass = '.banner__holder-mobile'
    }

    setTimeout(() => {
        gsap.set(`${parentClass} .banner__text-en`, { autoAlpha: 1 })
        gsap.set(`${parentClass} .banner__text-jp`, { autoAlpha: 1 })
        
        const bigTextSplit = new SplitText(`${parentClass} .banner__text-en`, {type: "chars,lines", linesClass: 'banner__lines-wrapper'});
        const smallTextSplit = new SplitText(`${parentClass} .banner__text-jp`, {type: "chars,lines", linesClass: 'banner__lines-wrapper',});

        gsap.timeline()
            .from(bigTextSplit.chars, {
                duration: 0.5, 
                y: '100%',
                stagger: 0.02,
            })
            .from(smallTextSplit.chars, {
                autoAlpha: 0,
                duration: 0.5, 
                y: '100%', 
                stagger: 0.02,
            })
    }, 500);
}

function galleryAnimationFade() {
    const galleryImages = document.querySelectorAll('.gallery__grid-item img:nth-child(1)')

    setInterval(() => {
        gsap.to(galleryImages, {
            autoAlpha: (gsap.getProperty(galleryImages[0], 'autoAlpha') === 0) ? 1 : 0,
            duration: 1 // Adjust the duration as needed
        });
    }, 6000);
}

function galleryAnimation() {
    const gallery_wrapper = document.querySelector('.gallery__grid')
    let activeArr = phpArrayGallery.slice(0, 17).map(item => item.id)

    // Function to generate a random number that is not present in activeArr array
    function randomizeNumber(maxNum) {
        let currNum

        do {
            currNum = Math.floor(Math.random() * (maxNum - 1) + 1);
        } while (activeArr.some(item => item === currNum));

        return currNum;
    }

    // Function to replace an element in activeArr at a random index
    function replaceArr(randomNum) {
        const randomIndex = Math.floor(Math.random() * activeArr.length);

        activeArr[randomIndex] = randomNum;

        return randomIndex
    }

    let imageGallery = []

    setInitialGallery(activeArr)

    // Function to set the intial gallery item with 1 paramater which is array of number
    // this function only run once
    function setInitialGallery(idsToFilter) {
        imageGallery = phpArrayGallery.filter(item => idsToFilter.includes(item.id));

        imageGallery.forEach((pag, index) => {
            const gallery_item = 
            `
            <div class="gallery__grid-item grid-item-${index + 1}">
                <img src="${pag['url']}" alt="" class="gallery__image">
            </div>
            `
            gallery_wrapper.innerHTML += gallery_item
        });
    }

    // Function to replace gallery image inside setInterval function
    function replaceGalleryItem(newImageId) {
        const randomIndex = replaceArr(newImageId)
        const galleryItem = document.querySelector(`.grid-item-${randomIndex + 1} img`)

        const selectedImage = phpArrayGallery.find((item) => {
            return item.id === newImageId
        })

        gsap.timeline({
            defaults: {
                duration: 1,
            }
        })
            .to(`.grid-item-${randomIndex + 1} img`, {
                autoAlpha: 0,
                onComplete: () => {
                    galleryItem.setAttribute('src', selectedImage.url)
                }
            })
            .to(`.grid-item-${randomIndex + 1} img`, {
                autoAlpha: 1
            })
    }
    
    setInterval(() => {
        const randomNum = randomizeNumber(phpArrayGallery.length)

        replaceGalleryItem(randomNum)
    }, 3000);
}

function runningText() {
    gsap.to('.join__running-text', {
        duration: 60,
        x : '-50%',
        repeat: -1,
        ease: "none"
    })
}

function joinGallery() {
    const swiper = new Swiper('.join__swiper', {
        modules: [Autoplay],
        slidesPerView: 'auto',
        loop: true,
        spaceBetween: 15,
        speed: 12000,
        allowTouchMove: false,
        autoplay: {
            delay: 0,
            reverseDirection: true,
        },
        breakpoints: {
            768: {
                spaceBetween: 20,
            },
            1400: {
                spaceBetween: 30,
            }
        }
    });
}

function servicesLottie(index) {
    if (index == 0) {
        LottieInteractivity({
            player: '#business-lottie',
            mode: 'chain',
            actions: [
                {
                    state: 'autoplay',
                    transition: 'onComplete',
                    frames: [0, 61],
                },
                {
                    state: 'loop',
                    transition: 'none',
                    frames: [62, 120],
                },
            ],
        });
    } else if (index == 1) {
        LottieInteractivity({
            player: '#marketing-lottie',
            mode: 'chain',
            actions: [
                {
                    state: 'autoplay',
                    transition: 'none',
                    frames: [0, 60],
                },
            ],
        });
    } else if (index == 3) {
        LottieInteractivity({
            player: '#system-lottie',
            mode: 'chain',
            actions: [
                {
                    state: 'autoplay',
                    transition: 'none',
                    frames: [0, 90],
                },
            ],
        });
    }

}

function servicesAnimation() {
    const businessLottie = document.querySelector('#business-lottie')
    const marketingLottie = document.querySelector('#marketing-lottie')
    const systemLottie = document.querySelector('#system-lottie')

    const businessLottieWrapper = document.querySelector('#business-lottie-mobile')
    const marketingLottieWrapper = document.querySelector('#marketing-lottie-mobile')
    const systemLottieWrapper = document.querySelector('#system-lottie-mobile')

    const services = document.querySelectorAll('.services__text-wrapper')
    const images = document.querySelectorAll('.services__pin-inner')

    if (window.innerWidth >= 768) {
        // pin the image container
        ScrollTrigger.create({
            trigger: '.services__pin-parent',
            pin: '.services__pin-parent',
            pinSpacing: false,
            start: 'center 60%',
            end: "+=" + (document.querySelector('.services').offsetHeight - document.querySelector('.services__pin-parent').offsetHeight),
            // markers: true,
        })
    
        // loop through all text to get the height of each services
        // use scrolltrigger to target the image with index, if scrolltrigger active, show image with active scrolltrigger index
        services.forEach((element, i) => {
            gsap.to(element, {
                scrollTrigger: {
                    trigger: element,
                    start: 'top 45%',
                    end: 'bottom 45%',
                    onEnter: () => {
                        gsap.to(images[i], {
                            autoAlpha: 1,
                        })

                        if (i == 2) {
                            gsap.from('.services__list-desktop .services__list-inner', {
                                y: 50,
                                autoAlpha: 0,
                                stagger: 0.1,
                                delay: 0.5
                            })
                        } else {
                            servicesLottie(i)
                        }
                    },
                    onEnterBack: () => {
                        gsap.to(images[i], {
                            autoAlpha: 1,
                        })
                    },
                    onLeave: () => {
                        gsap.to(images[i], {
                            autoAlpha: 0,
                        })
                    },
                    onLeaveBack: () => {
                        gsap.to(images[i], {
                            autoAlpha: 0,
                        })
                    },
                    // markers: true,
                }
            })
        });
    } else {
        businessLottieWrapper.appendChild(businessLottie)
        marketingLottieWrapper.appendChild(marketingLottie)
        systemLottieWrapper.appendChild(systemLottie)

        // resize() | Purpose: Resizes the animation to the dimensions of the device on which it is being played.
        businessLottie.addEventListener('ready', () => {
            businessLottie.resize()
        })

        marketingLottie.addEventListener('ready', () => {
            marketingLottie.resize()
        })

        systemLottie.addEventListener('ready', () => {
            systemLottie.resize()
        })

        services.forEach((element, i) => {
            gsap.to(element, {
                scrollTrigger: {
                    trigger: element,
                    start: 'top 50%',
                    end: 'bottom 50%',
                    onEnter: () => {
                        gsap.to(images[i], {
                            autoAlpha: 1,
                        })

                        if (i == 2) {
                            gsap.from('.services__list .services__list-inner', {
                                y: 50,
                                autoAlpha: 0,
                                stagger: 0.2,
                                delay: 0.5
                            })
                        } else {
                            servicesLottie(i)
                        }
                    },
                    onEnterBack: () => {
                        gsap.to(images[i], {
                            autoAlpha: 1,
                        })
                    },
                    onLeave: () => {
                        gsap.to(images[i], {
                            autoAlpha: 0,
                        })
                    },
                    onLeaveBack: () => {
                        gsap.to(images[i], {
                            autoAlpha: 0,
                        })
                    },
                }
            })
        });
    }
}

document.addEventListener('DOMContentLoaded', () => {
    animateCursor()
    galleryAnimationFade()
    // galleryAnimation()
    runningText()
    joinGallery()
    servicesAnimation()
})

window.addEventListener('load', () => {
    bannerTextAnimation()
})