import gsap from "gsap"

// FUNCTION CURSOR ANIMATION
function animateCursor() {

    /**
     * Create cursor element and append to body
     */
    const cursorElement = document.createElement("div");
    cursorElement.className = "cursor";
    document.body.appendChild(cursorElement);

    /**
     * Insert SVG inside cursor
     */
    cursorElement.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none"><circle cx="32.2891" cy="32.733" r="32" fill="url(#paint0_linear_2065_6796)" /><line x1="23.4434" y1="24.2803" x2="41.1351" y2="24.2803" stroke="white" stroke-width="1.17945" /><line x1="41.2343" y1="23.8871" x2="41.2343" y2="41.5789" stroke="white" stroke-width="1.17945" /><line x1="40.7972" y1="24.7923" x2="23.8643" y2="41.9926" stroke="white" stroke-width="1.17945" /><defs><linearGradient id="paint0_linear_2065_6796" x1="9.07239" y1="57.401" x2="53.7519" y2="11.5139" gradientUnits="userSpaceOnUse"><stop stop-color="#60B86A" /><stop offset="1" stop-color="#F8BC56" /></linearGradient></defs></svg>'

    /**
     * Animate element cursor with gsap
     */
    function onMouseMove(e) {
        gsap.to(cursorElement, {
            top: e.clientY,
            left: e.clientX,
            duration: 0.55,
            ease: 'expo.out',
        })
    }

    /**
     * gsap timeline for increase size when enter element that has cursor-hover class
     */
    const cursorTl = gsap.timeline({
        paused: true,
        defaults: {
            duration: 0.2,
            ease: 'none'
        }
    }).to('.cursor', {
        autoAlpha: 1,
        width: 64,
        height: 64,
    })

    const hoverAreas = document.querySelectorAll('.cursor-hover')

    for (let index = 0; index < hoverAreas.length; index++) {

        hoverAreas[index].addEventListener('mouseenter', () => {
            // cursorElement.classList.add('cursor--text')

            cursorTl.play()
            
        })

        hoverAreas[index].addEventListener('mouseleave', () => {

            cursorTl.reverse()
        })
        
    }

    document.body.addEventListener('mousemove', (e) => {
        onMouseMove(e)
    })

}

export { animateCursor };